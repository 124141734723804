import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import BehindScenes from '../components/BehindScenes/BehindScenes'

export default function BehindTheScenesPage({ data: { strapi: { behindTheScenes } }, location }) {

	return (
		<>
			<SEO title="Behind the scenes" pathname={ location.pathname } />

			<div className="content-page content-page--has-header">
				<div className="wrapper">
					<div className="content-page__header">
						<h1 className="content-page__heading">Behind the scenes</h1>
					</div>

					<BehindScenes behindTheScenes={ behindTheScenes } />
				</div>
			</div>
		</>
	)
}

export const query = graphql`
	query behindTheScenesPageQuery {
		strapi {
			behindTheScenes {
				id
				name
				role
				summary
				url
				image {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`